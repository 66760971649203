document.addEventListener("DOMContentLoaded", function(event) { 
  let toggle = document.querySelector("[href='#colapso1'")
  let collapse = document.getElementById("colapso1")
  let position = toggle.offsetTop - 20

  collapse.addEventListener('shown.bs.collapse', function () {
    window.scrollTo(0, position)
  })

  let toggle2 = document.querySelector("[href='#colapso3'")
  let collapse2 = document.getElementById("colapso3")
  let position2 = toggle2.offsetTop - 20

  collapse2.addEventListener('shown.bs.collapse', function () {
    window.scrollTo(0, position2)
  })
})
