import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "cardErrors", "amountField", "emailField", "amountContainer", "submitBtn", "commentsField" ]
  static values = {
    stripeKey: String
  }

  initialize() {
    this.stripe = Stripe(this.stripeKeyValue)
    this.selectedAmount = 50

    const elements = this.stripe.elements({
      locale: 'es'
    })

    var style = {
      base: {
        color: "#32325d",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      }
    }

    this.cardElement = elements.create("card", { style: style })
    this.cardElement.mount("#card-element")

    this.cardElement.on('change', function(event) {
      if (event.error) {
        this.cardErrorsTarget.textContent = event.error.message
      } else {
        this.cardErrorsTarget.textContent = ''
      }
    }.bind(this))
  }

  triggerAmountContainer(e) {
    let value = e.currentTarget.value

    if (value == 50) {
      this.amountContainerTarget.classList.remove('show')
      this.selectedAmount = 50
      this.submitBtnTarget.value = "Enviar $50 MXN"
      this.amountFieldTarget.removeAttribute("required")
    } else if (value == 100) {
      this.amountContainerTarget.classList.remove('show')
      this.selectedAmount = 100
      this.submitBtnTarget.value = "Enviar $100 MXN"
      this.amountFieldTarget.removeAttribute("required")
    } else {
      this.amountContainerTarget.classList.add('show')
      this.amountFieldTarget.setAttribute("required", "required")
      this.amountFieldTarget.focus()
    }
  }

  processAmountField(e) {
    let value = e.currentTarget.value
    this.submitBtnTarget.value = `Enviar $${value} MXN`
    this.selectedAmount = value
  }

  submitCardForm(e) {
    e.preventDefault()
    e.stopPropagation()

    if (!this.emailFieldTarget.value) {
      return false
    }

    Rails.disableElement(this.formTarget)

    let form_data = {
      amount: this.selectedAmount,
      email: this.emailFieldTarget.value,
      comments: this.commentsFieldTarget.value
    }

    Rails.ajax({
      type: "POST",
      url: '/payment_intents',
      data: new URLSearchParams(form_data).toString(),
      success: (response) => {
        this.handleStripePayment(response.stripe_secret)
      }
    })
  }

  handleStripePayment(stripe_secret) {
    this.stripe.confirmCardPayment(stripe_secret, {
      payment_method: {
        card: this.cardElement,
        billing_details: {
          email: this.emailFieldTarget.value
        }
      }
    }).then(function(result) {
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        this.cardErrorsTarget.textContent = result.error.message
        Rails.enableElement(this.formTarget)
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          return window.location = "/payment_intents/success"
        }
      }
    }.bind(this))
  }
}
