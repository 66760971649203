import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["successMessage"]

  initialize() {
    this.successMessageTarget.style.display = 'none'
  }

  handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()

    Rails.disableElement(this.element)

    Rails.ajax({
      type: "POST",
      url: this.element.getAttribute("action"),
      data: new URLSearchParams(new FormData(this.element)).toString(),
      success: (response) => {
        Rails.enableElement(this.element)

        this.element.reset()
        this.successMessageTarget.style.display = 'block'
      }
    })
  }
}
