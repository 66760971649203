import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    const os = this.getMobileOS()

    if (os == "Android") {
      this.element.setAttribute("href", "fb://page/104519198443249/")
    } else if (os == "iOS") {
      this.element.setAttribute("href", "fb://profile/104519198443249/")
    }
  }

  getMobileOS() {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      return "Android"
    }
    else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      return "iOS"
    }
    return "Other"
  }
}
